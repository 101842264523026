import React from "react"
import Layout from "../components/new-design/Layout"
import Seo from "../components/seo"

import { graphql, Link } from "gatsby"
// import path from "path"
export default function CategoryList({ location, data }) {
  return (
    <Layout>
      <Seo
        template
        // image={metaImage}//
        title="List"
        pathname={location.pathname}
      />
      <Lists content={data.allSanityListingCategory.edges} />
    </Layout>
  )
}

const Lists = ({ content }) => {
  return (
    <div className="py-12 mx-auto container w-11/12">
      {/* {JSON.stringify(content)} */}
      <div className="w-11/12 mx-auto">
        <div className="grid grid-cols-3 gap-2 mb-2">
          <pre className="h-8 w-full font-bold text-center flex items-center px-2 bg-black text-white">
            List Name
          </pre>
          <pre className="h-8 w-full font-bold text-center col-span-2 flex items-center px-2 bg-black text-white text-sm">
            Route/slug
          </pre>
        </div>
        {content.map((list, key) => {
          return (
            <div className="grid grid-cols-3 gap-2 mb-2" key={key}>
              <pre className="h-8 w-full flex truncate items-center px-2 bg-gray-50">
                <Link to={`/guide/${list.node.slug.current}/`}>
                  {key + 1 + ")"} {list.node.name}
                </Link>
              </pre>
              <pre className="h-8 w-full truncate col-span-2 flex items-center px-2 bg-gray-50 text-sm">
                <Link to={`/guide/${list.node.slug.current}/`}>
                  {list.node.slug.current}
                </Link>
              </pre>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    allSanityListingCategory {
      edges {
        node {
          name
          slug {
            current
          }
        }
      }
    }
  }
`
